/***** DENSE Regular *****/
@font-face {
    font-family: 'denseregular';
    src: url('../fonts/dense-regular-webfont.eot');
    src: url('../fonts/dense-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/dense-regular-webfont.woff2') format('woff2'),
         url('../fonts/dense-regular-webfont.woff') format('woff'),
         url('../fonts/dense-regular-webfont.ttf') format('truetype'),
         url('../fonts/dense-regular-webfont.svg#denseregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Regular *****/
@font-face {
    font-family: 'ralewayregular';
    src: url('../fonts/raleway-regular-webfont.eot');
    src: url('../fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-regular-webfont.woff2') format('woff2'),
         url('../fonts/raleway-regular-webfont.woff') format('woff'),
         url('../fonts/raleway-regular-webfont.ttf') format('truetype'),
         url('../fonts/raleway-regular-webfont.svg#ralewayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** RALEWAY Bold *****/
@font-face {
    font-family: 'ralewaybold';
    src: url('../fonts/raleway-bold-webfont.eot');
    src: url('../fonts/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/raleway-bold-webfont.woff2') format('woff2'),
         url('../fonts/raleway-bold-webfont.woff') format('woff'),
         url('../fonts/raleway-bold-webfont.ttf') format('truetype'),
         url('../fonts/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}

/***** FONT ICON *****/
@font-face {
    font-family: 'aiguillenoire';
    src: url('../fonts/aiguillenoire.eot?g1w6uq');
    src: url('../fonts/aiguillenoire.eot?g1w6uq#iefix') format('embedded-opentype'),
         url('../fonts/aiguillenoire.ttf?g1w6uq') format('truetype'),
         url('../fonts/aiguillenoire.woff?g1w6uq') format('woff'),
         url('../fonts/aiguillenoire.svg?g1w6uq#aiguillenoire') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'aiguillenoire' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tripadvisor:before {
    content: "\e907";
}
.icon-social-facebook:before {
    content: "\e906";
}
.icon-car:before {
    content: "\e900";
}
.icon-key:before {
    content: "\e901";
}
.icon-pointer:before {
    content: "\e902";
}
.icon-sun:before {
    content: "\e903";
}
.icon-facebook-official:before {
    content: "\e904";
}
.icon-arrow-right:before {
    content: "\e905";
}