html { font-size: 62.5%; }

$font-base:  'ralewayregular', sans-serif;
$font-bold:  'ralewaybold', sans-serif;
$font-title: 'denseregular', sans-serif;
$font-icon:  'aiguillenoire';
$image-path: '/images/';

$color-1:        #0085b5; //bleu clair
$color-1-darken: #006186; //bleu moyen
$color-2:        #00324e; //bleu nuit

$white: #fff;
$black: #000;

$color-bg:        $white;
$color-text:      $white;
$color-text-dark: $black;

$color-cancel: #bc2121;

$color-link: rgba($white, 0.85);
$color-link-hover: $white;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$grid-gutter-base:    0;
$grid-gutter-tablet:  0;
$grid-gutter-desktop: 0;
$grid-gutter-wide:    0;