@mixin retina() {
	@media only screen and (-webkit-min-device-pixel-ratio : 1.5),
	only screen and (min-device-pixel-ratio : 1.5) {
		@content;
	}
}

@mixin font-size($font-size: 16) {
	font-size : #{$font-size}px;
	font-size : calc(#{$font-size} / 10)rem;
}

@mixin clearfix() {
	*zoom : 1;
	&:before,
	&:after {
		display: table;
		content : "";
	}
	&:after {
		clear : both;
	}
}

@mixin input_mobile() { 
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
}