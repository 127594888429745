html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
*/
/***** TITLES *****/
.title-lev1 {
	position: relative;
	margin: 0 0 0.35em 0;

	font-family: $font-title;
	@include font-size(32);
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: $color-text;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include mq($from: xwide) {
		margin: 0 0 0.5em 0;
		@include font-size(34);
	}

	@include mq($from: xxwide) {
		@include font-size(36);
	}

	&.title-lev1--darken {
		color: $color-1-darken;
	}
}

.title-lev2 {
	position: relative;
	margin: 0 0 1.5em 0;

	font-family: $font-bold;
	@include font-size(20);
	text-transform: uppercase;
	color: $color-text;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@include mq($from: desktop) {
		@include font-size(22);
	}

	@include mq($from: wide) {
		@include font-size(24);
	}

	&:after {
		display: block;
		content: "";
		height: 2px;
		width: 20px;
		background-color: $color-text;

		position: absolute;
		left: 0;
		bottom: -6px;
	}

	&.title-lev2--darken {
		color: $color-1-darken;

		&:after {
			background-color: $color-1-darken;
		}
	}
}

.title-lev3 {
	margin: 2.2em 0 0.8em 0;

	font-family: $font-bold;
	@include font-size(14);
	letter-spacing: 0.05em;

	&:after {
		display: block;
		content: "";
		width: 12px;
		height: 1px;
		background-color: $color-text;
		background-color: rgba($color-text, 0.8);

		margin: 4px 0 0 0;
	}

	&.title-lev3-darken {
		color: $color-2;

		&:after {
			background-color: $color-2;
		}
	}
}

/***** TEXT *****/
p {
	font-family: $font-base;
	@include font-size(14);
	line-height: 1.6em;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.strong {
	font-family: $font-bold;
}

a {
	color: $color-link;
	transition: color 0.2s ease-in-out;

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-link-hover;
	}
}

ul.main-list {
	margin: 0 0 1em 0;

	font-family: $font-base;

	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

	li {
		margin: 0 0 0.4em 0;
		
		&:before {
			display: inline-block;
			content: "-";
			margin: 0 2px 0 0;
		}
	}
}

// ul.presta-list {

// 	li {
// 		display: inline-block;
// 		width: 80px;
// 		height: 80px;

// 		border: 2px solid $color-text;
// 		border-radius: 50%;

// 		font-family: $font-bold;
// 		@include font-size(12);
// 		text-transform: uppercase;
// 		text-align: center;
// 		vertical-align: middle;
// 	}
// }

/***** EDITOR_TEXT *****/
.editor-text {
	color: $color-text;

	strong {
		font-weight: 700;
	}

	p {
		line-height: 1.4;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 10%);
			}
		}
	}

	ul {
		margin: 0 0 1em 0;

		font-family: $font-base;

		-webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

		li {
			margin: 0 0 0.4em 0;
			
			&:before {
				display: inline-block;
				content: "-";
				margin: 0 2px 0 0;
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-title;
		text-transform: uppercase;
		@include font-size(20);
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-title;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}


/* 
 ######    ########    ######    ########   ####   #######   ##    ##   
##    ##   ##         ##    ##      ##       ##   ##     ##  ###   ##   
##         ##         ##            ##       ##   ##     ##  ####  ##   
 ######    ######     ##            ##       ##   ##     ##  ## ## ##   
      ##   ##         ##            ##       ##   ##     ##  ##  ####   
##    ##   ##         ##    ##      ##       ##   ##     ##  ##   ###   
 ######    ########    ######       ##      ####   #######   ##    ##   
*/
.section--content {
	@include clearfix;
	padding-top: 140px;
	height: auto;

	@include mq($from: desktop) {
		padding-top: 110px;
	}
}


/* 
##      ## ########      ###     ########   ########   ########   ########   
##  ##  ## ##     ##    ## ##    ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ##     ##   ##   ##   ##     ##  ##     ##  ##         ##     ##  
##  ##  ## ########   ##     ##  ########   ########   ######     ########   
##  ##  ## ##   ##    #########  ##         ##         ##         ##   ##    
##  ##  ## ##    ##   ##     ##  ##         ##         ##         ##    ##   
 ###  ###  ##     ##  ##     ##  ##         ##         ########   ##     ##  
*/
.wrapper {
	@include clearfix;
	padding: 20px;
	margin: 0 auto;

	@include mq($from: desktop) {
		padding: 30px;
	}

	@include mq($from: wide) {
		padding: 40px;
	}

	@include mq($from: xwide) {

	}
}

/***** WRAPPER--HEADER *****/
.wrapper--header {
	padding: 20px;

	@include mq($from: desktop) {
		margin: 0 auto;
		padding: 8px 20px;

		position: absolute;
		bottom: 0;

		transition: padding 0.2s ease-in-out;

		.ontop & {
			padding: 12px 20px;
		}

		.home & {
			padding: 12px 20px;
		}
	}
}

.wrapper--header-alt {
	padding: 0 20px;

	@include mq($from: desktop) {
		height: 100%;
		width: 100%;
		padding: 0 20px 0 0;
	}
}

/***** WRAPPER--MENU *****/
.wrapper--menu {

	@include mq($from: tablet) {
		padding: 0;
	}
}

/***** WRAPPER-SIDEBAR *****/
.wrapper-sidebar {

	@include mq($from: desktop) {
		padding: 15px;
		width: 100%;
	}

	@include mq($from: wide) {
		padding: 20px;
	}

	@include mq($from: xwide) {
		padding: 30px;
	}

	@include mq($from: xxwide) {
		padding: 40px;
	}
}

/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
/***** HEADER-MAIN *****/
.header-main {
	position: relative;
	z-index: 115;

	@include mq($from: desktop) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 60px;
		background-color: $color-bg;
		z-index: 115;

		transition: height 0.2s ease-in-out;

		.ontop & {
			position: absolute;
			top: 50px;
		}

		.home & {
			position: fixed;
			top: 50px;
		}
	}
}

/***** HEADER-ALT *****/
.header-alt {
	width: 100%;
	height: 40px;
	background-color: $color-2;

	@include mq($until: desktop) {
		position: fixed;
		top: 100px;
		z-index: 110;
	}

	@include mq($from: desktop) {
		width: 220px;
		height: 110px;
		z-index: 120;

		position: absolute;
		top: 0;
		right: 0;

		background-color: $white;
	}

	@include mq($from: wide) {
		width: 280px;
	}

	@include mq($from: xwide) {
		width: 340px;
	}

	@include mq($from: xxwide) {
		width:400px;
	}

	.header-alt--content-top {

		@include mq($until: desktop) {
			position: absolute;
			right: 20px;
			bottom: 60px;
		}

		@include mq($from: desktop) {
			position: absolute;
			top: 0;
			right: 0;
			width: 220px;
			height: 50px;

			.home & {
				position: fixed;
				background-color: $color-bg;
			}
		}

		@include mq($from: wide) {
			width: 280px;
		}

		@include mq($from: xwide) {
			width: 340px;
		}

		@include mq($from: xxwide) {
			width: 400px;
		}
	}

	.header-alt--content-bottom {
		width: 100%;
		height: auto;

		@include mq($from: desktop) {
			position: fixed;
			right: 0;
			top: 0;
			width: 220px;

			background-color: $color-2;

			transition: top 0.2s ease-in-out;

			.ontop & {
				position: absolute;
				margin-top: 50px;
			}

			.home & {
				position: fixed;
				margin-top: 50px;
			}
		}

		@include mq($from: wide) {
			width: 280px;
		}

		@include mq($from: xwide) {
			width: 340px;
		}

		@include mq($from: xxwide) {
			width: 400px;
		}
	}
}

.select-lang {

	@include mq($until: desktop) {
		float: right;
	}

	@include mq($from: desktop) {
		display: inline-block;
		width: auto;
		height: 100%;
	}

	li {
		display: inline-block;

		@include mq($from: desktop) {
			margin: 22px 0 0 0;
		}

		@include mq($from: wide) {
			margin: 18px 0 0 0;
		}

		&:first-of-type {
			margin: 0 3px 0 0;

			@include mq($from: desktop) {
				margin: 22px 2px 0 0;
			}

			@include mq($from: wide) {
				margin: 18px 2px 0 0;
			}
		}

		a {
			font-family: $font-base;
			@include font-size(15);
			text-transform: uppercase;
			color: #9c9e9f;

			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			&.current {
				color: $color-text;
				color: $color-2;
			}

			.no-touch &:hover,
			.no-touchevents &:hover,
			.no-touch &:focus,
			.no-touchevents &:focus,
			.touch &:active,
			.touchevents &:active {
				color: $color-1;
			}
		}
	}
}

.contact-top {

	@include mq($from: desktop) {
		position: absolute;
		top: 0;
		right: 10px;
	}
}

.tel {

	@include mq($from: desktop) {
		display: inline-block;
		margin-right: 10px;
		height: 50px;
		line-height: 50px;
		vertical-align: top;

		color: $color-1;
		@include font-size(18);
	}

	.en & {
		@include mq($from: wide) {
			@include font-size(17);
		}
	}
}

.facebook-link--container {

	@include mq($from: desktop) {
		display: inline-block;
		height: 50px;
		line-height: 55px;
	}
}

.facebook-link {
	display: inline-block;
	height: 25px;
	line-height: 23px;
	width: 25px;
	margin-top: 7px;

	border: 1px solid $white;
	border-color: rgba($white, 0.8);
	border-radius: 50%;

	@include font-size(16);
	color: $white;
	text-align: center;

	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out;

	@include mq($until: desktop) {
		float: right;
	}

	@include mq($from: desktop) {
		border-color: $color-2;
		margin-top: 0;

		// position: absolute;
		// top: 4px;
		// right: 10px;

		@include font-size(16);
		color: $color-2;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: $color-2;
		background-color: $white;

		@include mq($from: desktop) {
			color: $white;
			background-color: $color-1;
			border-color: $color-1;
		}
	}
}

.reservation-link {
	display: inline-block;
	height: 40px;
	line-height: 40px;

	padding: 0 0 0 26px;

	font-family: $font-title;
	@include font-size(24);
	text-transform: uppercase;
	letter-spacing: 0.08em;
	color: $color-text;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	transition: color 0.2s ease-in-out;

	@include mq($from: desktop) {
		height: 60px;
		line-height: 60px;

		padding: 0 0 0 52px;

		@include font-size(34);
	}

	span {
		width: 20px;
		height: 20px;

		position: absolute;
		top: 9px;
		left: 20px;

		display: inline-block;

		border-radius: 50%;
		border: 1px solid $white;
		background-color: transparent;

		transition: background-color 0.2s ease-in-out;

		@include mq($from: desktop) {
			width: 25px;
			height: 25px;

			top: 16px;
			border-width: 2px;
		}

		&:before {
			width: 20px;
			height: 20px;
			line-height: 18px;

			position: absolute;
			top: 0;
			left: 0;

			@include font-size(12);
			color: $color-text;
			text-align: center;

			transition: color 0.2s ease-in-out, transform 0.2s ease-in-out;

			@include mq($from: desktop) {
				width: 25px;
				height: 25px;
				line-height: 21px;

				left: -1px;

				@include font-size(14);
			}
		}
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {
		color: rgba($white, 0.9);

		span {
			background-color: $white;

			&:before {
				color: $color-2;
				transform: rotate(360deg);
			}
		}
	}
}



/***** LOGO *****/
.logo-mobile--container {
	
	@include mq($until: desktop) {
		width: 100%;
		height: 100px;
		position: fixed;
		top: 0;
		z-index: 110;
		background-color: $color-bg;
	}
}

.logo {
	display: block;
	text-indent: -9999px;
	position: relative;
	z-index: 115;
	
	width: 140px;
	height: 63px;
	background: transparent url('#{$image-path}logo.png') 0 0 no-repeat;
	background-size: 140px 63px;
	
	@include retina {
		background-image: url('#{$image-path}logo@2x.png');
		background-size: 140px 63px;
	}

	@include mq($from: desktop) {
		width: 121px;
		height: 44px;
		background-size: 121px 44px;

		background-image: url('#{$image-path}logo-only.png');

		transition: width 0.2s ease-in-out, height 0.2s ease-in-out, background-size 0.2s ease-in-out;

		@include retina {
			background-image: url('#{$image-path}logo-only@2x.png');
			background-size: 121px 44px;
		}

		.ontop & {
			width: 190px;
			height: 85px;

			background-image: url('#{$image-path}logo.png');
			background-size: 190px 85px;

			@include retina {
				background-image: url('#{$image-path}logo@2x.png');
				background-size: 190px 85px;
			}
		}

		.home & {
			width: 190px;
			height: 85px;

			background-image: url('#{$image-path}logo.png');
			background-size: 190px 85px;

			@include retina {
				background-image: url('#{$image-path}logo@2x.png');
				background-size: 190px 85px;
			}
		}
	}
}


/* 
##    ##      ###     ##     ##  
###   ##     ## ##    ##     ##  
####  ##    ##   ##   ##     ##  
## ## ##   ##     ##  ##     ##  
##  ####   #########   ##   ##   
##   ###   ##     ##    ## ##    
##    ##   ##     ##     ###     
*/
.navigation {
	z-index: 130;

	@include mq($until: desktop) {
		display: block;
		transition: right 0.35s ease-in-out;
		position: fixed;
		top: 0;
		bottom: 0;
		right: -100%;
		width: 100%;
		background-color: $color-2;
		background-color: rgba($color-2, 0.95);

		&.open {
			right: 0;
		}
	}

	@include mq($from: desktop) {
		width: 100%;
		height: 60px;
		line-height: 60px;

		top: 0;
	}
}

.menu {
	clear: both;
	@include clearfix;

	@include mq($until: desktop) {
		height: 70vh;
		margin: 13vh auto 15vh auto;

		text-align: center;
	}

	@include mq($from: desktop) {
		height: 60px;
		line-height: 62px;

		position: absolute;
		right: 240px;
		top: 0;

		transition: line-height 0.2s ease-in-out;

		.ontop & {
			line-height: 60px;
		}

		.home & {
			line-height: 60px;
		}
	}

	@include mq($from: wide) {
		right: 300px;
	}

	@include mq($from: xwide) {
		right: 380px;
	}

	@include mq($from: xxwide) {
		right: 440px;
	}

	li {
		@include mq($until: desktop) {
			height: 12vh;
			line-height: 12vh;
		}

		@include mq($from: desktop) {
			display: inline-block;
			margin: 0 12px 0 0;
		}

		@include mq($from: wide) {
			margin: 0 24px 0 0;
		}

		@include mq($from: xwide) {
			margin: 0 30px 0 0;
		}

		&:last-of-type {
			margin: 0;
		}
	}

	a {
		display: inline-block;
		position: relative;
		padding: 0 20px;
		margin: 4px auto;
		vertical-align: middle;

		text-align: center;

		font-family: $font-title;
		@include font-size(28);
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: $color-text;
		line-height: 1.2em;

		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		transition: color 0.3s ease-in-out;

		@include mq($until: desktop) {
			&:after {
				display: block;
				content: "";
				height: 2px;
				width: 0;
				background-color: $color-text;

				transition: width 0.3s ease-in-out;
			}
		}

		@include mq($from: desktop) {
			@include font-size(24);
			color: $color-2;

			width: auto;
			padding: 0;
			margin: 0;

			&:before {
				display: block;
				content: "";
				height: 2px;
				width: 0;
				background-color: $color-2;

				position: absolute;
				left: 0;
				top: -5px;

				transition: background-color 0.2s ease-in-out, width 0.2s ease-in-out;
			}
		}

		@include mq($from: wide) {
			@include font-size(25);

			transition: font-size 0.2s ease-in-out;
		}

		@include mq($from: xwide) {
			@include font-size(26);
		}

		@include mq($from: xxwide) {
			@include font-size(27);
		}

		.no-touch &:hover,
		.no-touchevents &:hover,
		.no-touch &:focus,
		.no-touchevents &:focus,
		.touch &:active,
		.touchevents &:active,
		&.current {

			@include mq($until: desktop) {
				color: $color-text;

				&:after {
					width: 100%;
				}
			}

			@include mq($from: desktop) {
				color: $color-1;

				&:before {
					background-color: lighten($color-1, 5%);
					width: 20px;
				}
			}
		}
	}

	&.menu-en {

		li {
			@include mq($from: desktop) {
				margin: 0 8px 0 0;
			}

			@include mq($from: wide) {
				margin: 0 12px 0 0;
			}

			@include mq($from: xwide) {
				margin: 0 20px 0 0;
			}

			@include mq($from: xxwide) {
				margin: 0 26px 0 0;
			}

			&:last-of-type {
				margin: 0;
			}

			a {
				@include mq($from: desktop) {
					@include font-size(22);
					letter-spacing: 0.01em;
				}

				@include mq($from: wide) {
					@include font-size(25);
					letter-spacing: 0.02em;
				}

				@include mq($from: xwide) {
					@include font-size(26);
					letter-spacing: 0.03em;
				}

				@include mq($from: xxwide) {
					@include font-size(27);
					letter-spacing: 0.045em;
				}
			}
		}
	}
}


/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
	position: fixed;
	width: 60px;
	height: 60px;
	top: 0;
	right: 0;
	z-index: 120;

	@include mq($from: desktop) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background: $color-1;
	transition: 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: 20px;
	}
}

.lines-button {
	position: absolute;
	height: auto;
	width: auto;
	right: 20px;
	top: 17px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	.no-touch .nav-toggle:hover &:not(.close) .lines, 
	.no-touch .nav-toggle:hover & .lines:before, 
	.no-touch .nav-toggle:hover & .lines:after {
		background: lighten($color-1, 10%);
	}

	@include mq($from: desktop) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background: transparent;

	&:before, &:after {
		background: $color-text;
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}


/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/
.bg-home {
	position: relative;
	height: 100vh;
	height: calc(100vh - 140px);
	width: 100%;

	background: transparent url('#{$image-path}home-winter.jpg') top center no-repeat;
	background-size: cover;

	transition: height 0.2s ease-in-out;

	@include mq($from: desktop) {
		position: fixed;
		width: calc(100% - 220px);
		height: calc(100vh - 110px);
	}

	@include mq($from: wide) {
		width: calc(100% - 280px);
	}

	@include mq($from: xwide) {
		width: calc(100% - 340px);
	}

	@include mq($from: xxwide) {
		width: calc(100% - 400px);
	}
}

.special-offer {
	position: absolute;
	background-color: $color-2;
	background-color: rgba($color-2, 0.8);
	padding: 15px 20px;
	max-width: 450px;

	p {
		display: block;
		line-height: 1.4em;
		

		&.offer-title {
			font-family: $font-title;
			@include font-size(24);
			line-height: 1em;
			margin-top: 5px;

			@include mq($from: desktop) {
				@include font-size(26);
			}
		}

		&.offer-title--margin-bot {
			margin: 0 0 5px 0;
		}
	}
	.offer-img {
		background: transparent url('#{$image-path}pass-loisirs.png') no-repeat;
		background-size: 130px 80px;
		width: 130px;
		height: 80px;
		margin: 20px 0 5px 0;
	}
}


/* 
 ######    ####  ########   ########   ########      ###     ########   
##    ##    ##   ##     ##  ##         ##     ##    ## ##    ##     ##  
##          ##   ##     ##  ##         ##     ##   ##   ##   ##     ##  
 ######     ##   ##     ##  ######     ########   ##     ##  ########   
      ##    ##   ##     ##  ##         ##     ##  #########  ##   ##    
##    ##    ##   ##     ##  ##         ##     ##  ##     ##  ##    ##   
 ######    ####  ########   ########   ########   ##     ##  ##     ##  
*/
.sidebar-home {
	width: 100%;

	@include mq($from: desktop) {
		width: 220px;

	position: relative;
		z-index: 10;
		width: 100%;
	}
}

.sidebar-content {

	@include mq($from: desktop) {
		float: right;
		width: 220px;
		
		max-height: calc(100vh - 110px);	
	}

	@include mq($from: wide) {
		width: 280px;
	}

	@include mq($from: xwide) {
		width: 340px;
	}

	@include mq($from: xxwide) {
		width: 400px;
	}
}

.sidebar-section {
	width: 100%;
	height: auto;

	&.sidebar-section--light {
		background-color: $color-bg;
		color: $color-text-dark;
	}

	&.sidebar-section--dark {
		background-color: $color-1;
	}

	&.sidebar-section--darken {
		background-color: $color-1-darken;
	}

	p {
		@include font-size(13);

		@include mq($from: desktop) {
			@include font-size(12);
		}

		@include mq($from: xwide) {
			@include font-size(13);
		}

		@include mq($from: xxwide) {
			@include font-size(14);
		}
	}

	img {
		display: block;
		max-width: 100%;
		width: 100%;
		height: auto;
		margin: 15px 0 0 0;

		@include mq(mobileLandscape, desktop) {
			max-width: 60%;
		}

		@include mq($from: xwide) {
			margin-top: 25px;
		}

		@include mq($from: xxwide) {
			margin-top: 30px;
		}
	}
}


/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.block {
	
	@include mq($from: desktop) {
		min-height: 400px;
	}
}

.block-intro {

	.wrapper {
		
		@include mq($from: tablet) {
			max-width: 720px;
			padding: 40px 0;
		}

		@include mq($from: desktop) {
			max-width: 780px;
			padding: 60px 0;
		}

		@include mq($from: wide) {
			max-width: 800px;
		}
	}

	.title-lev2 {
		color: $color-2;

		&:after {
			background-color: $color-2;
		}
	}

	p {
		color: $color-text-dark;

		&.half-col {

			@include mq($from: tablet) {
				float: left;
			}

			&:first-of-type {
				@include mq($from: tablet) {
					margin-right: 40px;
				}

				@include mq($from: desktop) {
					margin-right: 80px;
				}

				@include mq($from: wide) {
					margin-right: 100px;
				}

				@include mq($from: xwide) {
					margin-right: 120px;
				}
			}	
		}
	}
}

.block-img {
	background-color: transparent;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	height: 200px;

	&.block-img--hotel-banner {
		background-image: url('#{$image-path}hotel2.jpg');
	}

	&.block-img--hotel1 {
		background-image: url('#{$image-path}hotel1.jpg');
	}

	&.block-img--hotel2 {
		background-image: url('#{$image-path}hotel3.jpg');
	}

	&.block-img--service-banner {
		background-image: url('#{$image-path}service2.jpg');
	}

	&.block-img--service1 {
		background-image: url('#{$image-path}service1.jpg');

		@include retina {
			background-image: url('#{$image-path}service1@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--service2 {
		background-image: url('#{$image-path}service4.jpg');

		@include retina {
			background-image: url('#{$image-path}service4@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--service3 {
		background-image: url('#{$image-path}service3.jpg');

		@include retina {
			background-image: url('#{$image-path}service5@3x.jpg');
			background-size: cover;
		}
	}

	&.block-img--tarif-banner {
		background-image: url('#{$image-path}tarif3.jpg');
	}

	&.block-img--tarif1 {
		background-image: url('#{$image-path}tarif1.jpg');
	}

	&.block-img--tarif2 {
		background-image: url('#{$image-path}tarif2.jpg');
	}

	&.block-img--valloire1 {
		background-image: url('#{$image-path}valloire1.jpg');

		@include retina {
			background-image: url('#{$image-path}valloire1@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--valloire2 {
		background-image: url('#{$image-path}valloire2.jpg');

		@include retina {
			background-image: url('#{$image-path}valloire2@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--valloire3 {
		background-image: url('#{$image-path}valloire3.jpg');

		@include retina {
			background-image: url('#{$image-path}valloire3@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--contact1 {
		background-image: url('#{$image-path}contact2.jpg');

		@include retina {
			background-image: url('#{$image-path}contact2@2x.jpg');
			background-size: cover;
		}
	}

	&.block-img--contact2 {
		background-image: url('#{$image-path}contact3.jpg');

		@include retina {
			background-image: url('#{$image-path}contact3@2x.jpg');
			background-size: cover;
		}
	}
}

.block-text {

	&.block-text--color1 {
		background-color: $color-1;
	}

	&.block-text--color1-darken {
		background-color: $color-1-darken;
	}

	.wrapper {

		@include mq($from: desktop) {
			max-width: 90%;
		}

		@include mq($from: wide) {
			max-width: 80%;
		}
	}
}


/* 
##     ##   #######   ########   ########   ##         
##     ##  ##     ##     ##      ##         ##         
##     ##  ##     ##     ##      ##         ##         
#########  ##     ##     ##      ######     ##         
##     ##  ##     ##     ##      ##         ##         
##     ##  ##     ##     ##      ##         ##         
##     ##   #######      ##      ########   ########   
*/
ul.presta-list {
	margin: 20px auto;
	text-align: center;

	li {
		width: auto;
		margin: 0 auto 20px auto;

		text-align: center;

		@include mq($from: tablet) {
			display: inline-block;
			vertical-align: top;
			margin: 0 25px;
		}

		@include mq($from: desktop) {
			margin: 0 35px;
		}

		@include mq($from: wide) {
			margin: 0 45px;
		}

		@include mq($from: xwide) {
			margin: 0 50px;
		}

		@include mq($from: xxwide) {
			margin: 0 60px;
		}

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}

		span.presta-icon {
			display: block;

			@include font-size(48);
			color: $color-2;

			@include mq($from: tablet) {
				@include font-size(54);
			}

			@include mq($from: desktop) {
				@include font-size(64);
			}

			@include mq($from: wide) {
				@include font-size(78);
			}

			@include mq($from: xwide) {
				@include font-size(90);
			}

			@include mq($from: xxwide) {
				@include font-size(100);
			}
		}

		p {
			margin-top: 10px;

			@include font-size(15);
			color: $color-2;
			text-align: center;

			@include mq($from: tablet) {
				@include font-size(16);
			}

			@include mq($from: wide) {
				@include font-size(17);
			}
		}
	}
}


/* 
########      ###     ########   ####  ########    ######    
   ##        ## ##    ##     ##   ##   ##         ##    ##   
   ##       ##   ##   ##     ##   ##   ##         ##         
   ##      ##     ##  ########    ##   ######      ######    
   ##      #########  ##   ##     ##   ##               ##   
   ##      ##     ##  ##    ##    ##   ##         ##    ##   
   ##      ##     ##  ##     ##  ####  ##          ######    
*/
table.price-table,
table.price-table th,
table.price-table td {
   border: 1px solid $color-2;
}

table.price-table {
	border-collapse: collapse;
	margin: 20px 0 40px 0;

	td {
		padding: 10px;
		vertical-align: middle;
		width: 25%;

		color: $color-2;
		@include font-size(12);

		@include mq($from: tablet) {
			text-align: center;
		}

		@include mq($from: desktop) {
			@include font-size(13);
		}

		@include mq($from: wide) {
			padding: 12px;
			@include font-size(14);
		}
	}
}


/* 
   ###      ######    ########   ##     ##  
  ## ##    ##    ##      ##      ##     ##  
 ##   ##   ##            ##      ##     ##  
##     ##  ##            ##      ##     ##  
#########  ##            ##      ##     ##  
##     ##  ##    ##      ##      ##     ##  
##     ##   ######       ##       #######   
*/
.link-actu {
	display: inline-block;
	height: 32px;
	line-height: 29px;

	margin: 20px 0 10px 0;
	padding: 0 10px;

	border: 1px solid $white;
	border-color: rgba($white, 0.6);

	@include font-size(13);
	letter-spacing: 0.05em;
	color: $color-text;

	@include mq($from: tablet) {
		margin: 30px 0 20px 0;
	}

	&:after {
		display: inline-block;
		margin-left: 5px;

		font-family: $font-icon;
		content: "\e905";
		color: $white;
		@include font-size(10);

		transition: margin-left 0.2s ease-in-out;
	}

	.no-touch &:hover,
	.no-touchevents &:hover,
	.no-touch &:focus,
	.no-touchevents &:focus,
	.touch &:active,
	.touchevents &:active {

		&:after {
			margin-left: 10px;
		}
	}
}

.link {
	font-weight: 800 !important;
	color: #048ebf;
}

/* 
 ######     #######   ##    ##   ########      ###      ######    ########   
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
##         ##     ##  ##  ####      ##      #########  ##            ##      
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
 ######     #######   ##    ##      ##      ##     ##   ######       ##      
*/
.map {
	height: 100%;
}


/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
	background-color: $color-2;

	.wrapper {

		@include mq($from: desktop) {
			padding: 20px 30px;
		}

		@include mq($from: wide) {
			padding: 20px 40px;
		}
	}

	p {
		@include font-size(12);
		float: left;
	}

	.social-list {
		float: right;

		li {
			display: inline-block;
			margin: 0 2px 0 0;

			&:last-of-type {
				margin: 0;
			}

			a {
				@include font-size(16);
			}
		}
	}
}